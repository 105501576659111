import {
	data,
	Link,
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLoaderData,
} from 'react-router'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import appleTouchIconAssetUrl from '#app/assets/favicons/apple-touch-icon.png'
import faviconAssetUrl from '#app/assets/favicons/favicon.svg'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { Footer } from '#app/components/main-footer.tsx'
import { MainNavigationMenu } from '#app/components/main-nav.tsx'
import { EpicProgress } from '#app/components/progress-bar.tsx'
import { useToast } from '#app/components/toaster.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { href as iconsHref } from '#app/components/ui/icon.tsx'
import { EpicToaster } from '#app/components/ui/sonner.tsx'
import { UserDropdown } from '#app/components/user-dropdown.tsx'
import {
	ThemeSwitch,
	useOptionalTheme,
	useTheme,
} from '#app/routes/_app+/resources+/theme-switch.tsx'
import tailwindStyleSheetUrl from '#app/styles/tailwind.css?url'
import { getUserId, logout } from '#app/utils/auth.server.ts'
import { ClientHintCheck, getHints } from '#app/utils/client-hints.tsx'
import { prisma } from '#app/utils/db.server.ts'
import { getEnv } from '#app/utils/env.server.ts'
import { pipeHeaders } from '#app/utils/headers.server.ts'
import { honeypot } from '#app/utils/honeypot.server.ts'
import { combineHeaders, getDomainUrl } from '#app/utils/misc.tsx'
import { useNonce } from '#app/utils/nonce-provider.ts'
import { type Theme, getTheme } from '#app/utils/theme.server.ts'
import { makeTimings, time } from '#app/utils/timing.server.ts'
import { getToast } from '#app/utils/toast.server.ts'
import { useOptionalUser } from '#app/utils/user.ts'
import { type Route } from './+types/_layout.tsx'

export const links: Route.LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		{ rel: 'preload', href: iconsHref, as: 'image' },
		{
			rel: 'icon',
			href: '/favicon.ico',
			sizes: '48x48',
		},
		{ rel: 'icon', type: 'image/svg+xml', href: faviconAssetUrl },
		{ rel: 'apple-touch-icon', href: appleTouchIconAssetUrl },
		{
			rel: 'manifest',
			href: '/site.webmanifest',
			crossOrigin: 'use-credentials',
		} as const, // necessary to make typescript happy
		{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
	].filter(Boolean)
}

export const meta: Route.MetaFunction = ({ data }) => {
	return [
		{ title: data ? 'Clearwater Farms 1' : 'Error | Clearwater Farms 1' },
		{
			name: 'description',
			content: `Irrigation Management - Clearwater Farms Unit 1`,
		},
	]
}

export async function loader({ request }: Route.LoaderArgs) {
	const timings = makeTimings('root loader')
	const userId = await time(() => getUserId(request), {
		timings,
		type: 'getUserId',
		desc: 'getUserId in root',
	})

	const user = userId
		? await time(
				() =>
					prisma.user.findUnique({
						select: {
							id: true,
							member: true,
							username: true,
							display: true,
							image: { select: { id: true } },
							ports: {
								select: {
									ditch: true,
									position: true,
									entry: true,
									section: true,
								},
							},
							roles: {
								select: {
									name: true,
									permissions: {
										select: { entity: true, action: true, access: true },
									},
								},
							},
						},
						where: { id: userId },
					}),
				{ timings, type: 'find user', desc: 'find user in root' },
			)
		: null
	if (userId && !user) {
		console.info('something weird happened')
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await logout({ request, redirectTo: '/' })
	}
	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = await honeypot.getInputProps()

	const open = await time(
		() =>
			prisma.schedule.findFirst({
				select: { date: true },
				where: { state: 'open' },
				orderBy: { date: 'desc' },
			}),
		{
			timings,
			type: 'find open schedule',
			desc: 'find open schedule in root',
		},
	)
	const closed = await time(
		() =>
			prisma.schedule.findMany({
				select: { date: true },
				where: { state: 'closed' },
				orderBy: { date: 'desc' },
				take: 2,
			}),
		{
			timings,
			type: 'find latest closed schedule',
			desc: 'find latest closed schedule in root',
		},
	)

	return data(
		{
			user,
			open,
			closed,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			ENV: getEnv(),
			toast,
			honeyProps,
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
			),
		},
	)
}

export const headers: Route.HeadersFunction = pipeHeaders

function Document({
	children,
	nonce,
	theme = 'light',
	env = {},
}: {
	children: React.ReactNode
	nonce: string
	theme?: Theme
	env?: Record<string, string | undefined>
}) {
	const allowIndexing = ENV.ALLOW_INDEXING !== 'false'
	return (
		<html lang="en" className={`${theme} h-full overflow-x-hidden`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				{allowIndexing ? null : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<Links />
			</head>
			<body className="bg-background text-foreground">
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	)
}

export function Layout({ children }: { children: React.ReactNode }) {
	// if there was an error running the loader, data could be missing
	const data = useLoaderData<typeof loader | null>()
	const nonce = useNonce()
	const theme = useOptionalTheme()
	return (
		<Document nonce={nonce} theme={theme} env={data?.ENV}>
			{children}
		</Document>
	)
}

function App() {
	const data = useLoaderData<typeof loader>()
	const nonce = useNonce()
	const theme = useTheme()
	useToast(data.toast)

	return (
		<Document nonce={nonce} theme={theme} env={data.ENV}>
			<div className="flex min-h-screen flex-col">
				<header className="sticky top-0 flex flex-col bg-background px-6 py-6 text-foreground hover:z-10">
					<div className="flex items-center justify-between gap-4 md:gap-8">
						<img
							src="/img/cwf.svg"
							className="dark:light-filter h-12"
							alt="CWF"
						/>
						<Link
							to="/"
							className="group flex flex-wrap items-center leading-snug md:max-2xl:hidden"
						>
							<span className="text-lg font-bold transition group-hover:translate-y-1">
								Clearwater Farms&nbsp;
							</span>
							<span className="text-md text-nowrap font-light transition group-hover:-translate-y-1">
								Unit 1
							</span>
						</Link>
						<div id="row1-nav" className="flex max-md:hidden">
							<MainNavigationMenu open={data.open} closed={data.closed} />
						</div>
						<div id="row1-profile" className="flex gap-1 md:max-lg:hidden">
							<ThemeSwitch userPreference={data.requestInfo.userPrefs.theme} />
							<UserProfile />
						</div>
					</div>
					<div id="row2-icons" className="flex md:hidden">
						<MainNavigationMenu open={data.open} closed={data.closed} />
					</div>
					<div
						id="row2-logo"
						className="hidden w-full justify-between p-1 lg:max-2xl:flex"
					>
						<Link
							to="/"
							className="group flex flex-wrap items-center leading-snug"
						>
							<span className="text-lg font-bold transition group-hover:translate-y-1">
								Clearwater Farms&nbsp;
							</span>
							<span className="text-md font-light transition group-hover:-translate-y-1">
								Unit 1
							</span>
						</Link>
					</div>
					<div
						id="row2-profile"
						className="hidden w-full justify-between md:max-lg:flex"
					>
						<Link
							to="/"
							className="group flex flex-wrap items-center leading-snug"
						>
							<span className="text-lg font-bold transition group-hover:translate-y-1">
								Clearwater Farms&nbsp;
							</span>
							<span className="text-md font-light transition group-hover:-translate-y-1">
								Unit 1
							</span>
						</Link>
						<div className="flex flex-row gap-1">
							<ThemeSwitch userPreference={data.requestInfo.userPrefs.theme} />
							<UserProfile />
						</div>
					</div>
				</header>
				<Outlet />
				<Footer />
			</div>
			<EpicToaster closeButton position="top-center" theme={theme} />
			<EpicProgress />
		</Document>
	)
}

function UserProfile() {
	const user = useOptionalUser()
	return (
		<div className="flex items-center gap-5">
			{user ? (
				<UserDropdown />
			) : (
				<Button asChild variant="default" size="lg">
					<Link to="/login" className="text-nowrap">
						Log In
					</Link>
				</Button>
			)}
		</div>
	)
}

function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	return (
		<HoneypotProvider {...data.honeyProps}>
			<App />
		</HoneypotProvider>
	)
}

export default AppWithProviders

// this is a last resort error boundary. There's not much useful information we
// can offer at this level.
export const ErrorBoundary = GeneralErrorBoundary
